.App-logo {
  animation: App-logo-spin infinite 20s linear;
}

.app-content {
  height: calc(100vh - 80px);
  overflow-y: auto;
}

h1,
h1+span {
  padding: 0 20px;
}

.jumbotron ul {
  font-size: 21px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}