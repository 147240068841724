.svc-carry-forward-panel-wrapper {
  width: 100%;
  container-type: inline-size;
}

.svc-carry-forward-panel {
  box-sizing: border-box;
  width: 100%;
  padding: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(8 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  border-radius: var(--sjs-base-unit, var(--base-unit, 8px));
  background-color: #F8F8F8;
  text-align: center;
  font-weight: 400;
  font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  color: rgba(0, 0, 0, 0.45);
}

@container (max-width: 176px) {
  .svc-carry-forward-panel {
    display: none;
  }
}
@container (max-width: 496px) {
  .svc-carry-forward-panel {
    padding: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
}
.svc-carry-forward-panel__link .svc-action-button {
  padding: 0;
  border: none;
  font-weight: inherit;
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
}
.svc-carry-forward-panel__link .svc-action-button:hover, .svc-carry-forward-panel__link .svc-action-button:focus {
  background-color: transparent;
}

svc-question {
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
}

.svc-question__adorner {
  position: relative;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}
.svc-question__adorner .sv-action-bar {
  overflow: visible;
}
.svc-question__adorner .sv-action-bar .sv-action:not(:first-child) .sv-action-bar-item {
  border: none;
}
.svc-question__adorner .sv-action-bar .sv-action:not(:first-child) .sv-action-bar-item:active {
  opacity: 0.5;
  background-color: var(--background-dim, #f3f3f3);
}

.svc-question__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  width: 100%;
  height: auto;
  flex-grow: 1;
  border-radius: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  box-sizing: border-box;
  padding: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(8 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  background: var(--sjs-general-backcolor, var(--background, #fff));
  box-shadow: var(--sjs-shadow-small, 0px 1px 2px 0px rgba(0, 0, 0, 0.15));
  outline: none;
}

.svc-question__content .sd-selectbase:not(.sd-imagepicker) {
  padding-left: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  margin-left: calc(-5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.svc-question__content .sd-table .sd-selectbase:not(.sd-imagepicker) {
  overflow-x: visible;
  padding-left: 0;
  margin-left: 0;
}
.svc-question__content .sd-question.sd-question--table {
  overflow-x: initial;
}
.svc-question__content .sd-multipletext__item-title {
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
}
.svc-question__content .sd-multipletext__item-container.sd-input:focus-within {
  box-shadow: var(--sjs-shadow-inner, inset 0px 1px 2px 0px rgba(0, 0, 0, 0.15));
}
.svc-question__content .sd-matrixdynamic__btn.sd-matrixdynamic__add-btn {
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
  opacity: 0.25;
}
.svc-question__content .sjs_sp_placeholder {
  user-select: all;
  pointer-events: all;
}

.svc-question__content > div {
  overflow: visible;
}

.svc-question__content .sd-panel__content {
  overflow: auto;
}

.svc-panel__placeholder {
  color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  text-wrap: wrap;
  text-align: center;
  font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  font-style: normal;
  font-weight: 400;
  line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.svc-question__content-actions {
  position: absolute;
  opacity: 0;
  bottom: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  inset-inline-start: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  inset-inline-end: calc(3.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.svc-question__content-actions:focus-within {
  opacity: 1;
}
.svc-question__content-actions .sv-action--convertTo {
  max-width: max-content;
}
.svc-question__content-actions .sv-action--convertTo .sv-action-bar-item--icon {
  max-width: 100%;
  padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.svc-question__content-actions .sv-action--convertTo .sv-action-bar-item__title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  justify-content: left;
}
.svc-question__content-actions .sv-action--convertTo .sv-action-bar-item__title::after {
  content: " ";
  display: inline-block;
  margin-inline-start: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  width: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  vertical-align: bottom;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 11L13 6H3L8 11Z' fill='%23ff9814'/%3E%3C/svg%3E%0A");
}
.svc-question__content-actions .sv-action--convertTo .sv-action-bar-item__icon use {
  fill: var(--sjs-primary-backcolor, var(--primary, #19b394));
}
.svc-question__content-actions .sv-action--convertTo .sv-action-bar-item__icon:not(:last-child) {
  display: none;
}
.svc-question__content-actions .sv-action--convertTo .sv-action-bar-item__title--with-icon {
  margin-inline-start: 0;
  margin-inline-end: 0;
}
.svc-question__content-actions .sv-action--convertTo.sv-action--hidden {
  min-width: 0;
}
.svc-question__content-actions .sv-action--convertTo-last {
  margin-inline-end: auto;
}
.svc-question__content-actions .svc-action-bar-item--right:first-of-type {
  margin-inline-start: auto;
}
.svc-question__content-actions .sv-dots {
  width: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  margin-left: var(--sjs-base-unit, var(--base-unit, 8px));
  margin-right: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.svc-question__content-actions .sv-dots.sv-action--hidden {
  width: 0;
  margin: 0;
}
.svc-question__content-actions .sv-dots.sv-action--hidden .sv-action__content {
  display: none;
}
.svc-question__content-actions .sv-dots .sv-dots__item {
  margin-left: 0;
}
.svc-question__content-actions .sv-dots__item use {
  fill: var(--sjs-secondary-backcolor, var(--secondary, #ff9814));
}

.svc-question__content:focus,
.svc-hovered > .svc-question__content {
  box-shadow: 0 0 0 2px var(--sjs-secondary-backcolor-light, var(--secondary-light, rgba(255, 152, 20, 0.25)));
}

.svc-question__content--selected:not(.svc-question__content--dragged) {
  box-shadow: 0 0 0 2px var(--sjs-secondary-backcolor, var(--secondary, #ff9814));
}

.svc-question__content--selected:not(.svc-question__content--dragged) > .svc-question__content-actions,
.svc-creator:not(.svc-creator--mobile) .svc-page .svc-hovered > .svc-question__content:not(.svc-question__content--dragged) > .svc-question__content-actions {
  opacity: 1;
}

.svc-question__content--dragged {
  background: var(--sjs-general-backcolor-dim-light, var(--background-dim-light, #f9f9f9));
}
.svc-question__content--dragged .sd-question__title {
  color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
}
.svc-question__content--dragged.svc-question__content--selected .svc-question__content-actions {
  display: none;
}

.svc-question__content .sd-question.sd-question--table {
  margin-top: calc(-1 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding-top: var(--sjs-base-unit, var(--base-unit, 8px));
}

.svc-dragged-element-shortcut {
  height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  border-radius: calc(12.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  background-color: var(--sjs-general-backcolor, var(--background, #fff));
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
  padding: var(--sjs-base-unit, var(--base-unit, 8px)) calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding-right: calc(3.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  cursor: grabbing;
  position: absolute;
  z-index: 1000;
  box-shadow: var(--sjs-shadow-large, 0px 8px 16px 0px rgba(0, 0, 0, 0.1));
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.svc-dragged-element-shortcut .sv-svg-icon use {
  fill: var(--sjs-primary-backcolor, var(--primary, #19b394));
}

.svc-dragged-element-shortcut--selected {
  border: 2px solid var(--sjs-secondary-backcolor, var(--secondary, #ff9814));
}

.svc-dragged-element-shortcut__text {
  --small-bold-font-size: var(--sjs-font-size, calc(2 * var(--sjs-base-unit, var(--base-unit, 8px))));
  font-family: var(--sjs-font-family, var(--font-family));
  font-style: normal;
  font-weight: 600;
  font-size: calc(0.75 * var(--small-bold-font-size));
  line-height: var(--sjs-font-size, calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))));
  margin-left: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.svc-dragged-element-shortcut__icon {
  position: absolute;
  width: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.svc-question__content--drag-over-inside .svc-panel__placeholder_frame,
.svc-hovered > .svc-question__content--drag-over-inside .svc-panel__placeholder_frame {
  box-shadow: 0 0 0 1px var(--sjs-primary-backcolor, var(--primary, #19b394));
  border: 1px solid var(--sjs-primary-backcolor, var(--primary, #19b394));
  background: var(--sjs-primary-backcolor-light, var(--primary-light, rgba(25, 179, 148, 0.1)));
}
.svc-question__content--drag-over-inside .svc-panel__placeholder_frame > .svc-question__content-actions,
.svc-hovered > .svc-question__content--drag-over-inside .svc-panel__placeholder_frame > .svc-question__content-actions {
  opacity: 1;
}

.svc-question__content--drag-over-left:before {
  content: " ";
  position: absolute;
  left: -9px;
  background: var(--sjs-secondary-backcolor, var(--secondary, #ff9814));
  top: 0;
  height: 100%;
  width: 2px;
}

.svc-question__content--drag-over-right:after {
  content: " ";
  position: absolute;
  right: -9px;
  background: var(--sjs-secondary-backcolor, var(--secondary, #ff9814));
  top: 0;
  height: 100%;
  width: 2px;
}

.sd-panel .svc-question__content--drag-over-left:before {
  left: -6px;
}
.sd-panel .svc-question__content--drag-over-right:after {
  right: -6px;
}

.svc-question__content--drag-over-top:before {
  content: " ";
  position: absolute;
  left: 0;
  background: var(--sjs-secondary-backcolor, var(--secondary, #ff9814));
  top: -9px;
  width: 100%;
  height: 2px;
}

.svc-question__content--drag-over-bottom:before {
  content: " ";
  position: absolute;
  left: 0;
  background: var(--sjs-secondary-backcolor, var(--secondary, #ff9814));
  bottom: -9px;
  width: 100%;
  height: 2px;
}

.svc-question__content--panel .sd-row > div:first-child .svc-question__content--drag-over-left,
.sd-panel__content .sd-row > div:first-child .svc-question__content--drag-over-left {
  margin-left: 8px;
  width: calc(100% - 8px);
}
.svc-question__content--panel .sd-row > div:last-child .svc-question__content--drag-over-right,
.sd-panel__content .sd-row > div:last-child .svc-question__content--drag-over-right {
  margin-right: 8px;
  width: calc(100% - 8px);
}
.svc-question__content--panel .svc-question__content--drag-over-top:before,
.sd-panel__content .svc-question__content--drag-over-top:before {
  top: -6px;
}
.svc-question__content--panel .svc-question__content--drag-over-bottom:before,
.sd-panel__content .svc-question__content--drag-over-bottom:before {
  bottom: -6px;
}

.svc-question__content-actions .sv-action-bar {
  box-sizing: border-box;
  padding: 0;
  justify-content: center;
}

svc-question sv-action-bar,
svc-question .sv-action-bar,
.svc-question sv-action-bar,
.svc-question .sv-action-bar {
  padding: 0;
}

.svc-panel__edge {
  position: absolute;
  border: 1px solid orange;
  height: 10px;
  width: 100%;
}

.svc-panel__edge--top {
  top: 0;
}

.svc-panel__edge--bottom {
  bottom: 0;
}

.svc-question__adorner--start-with-new-line[data-sv-drop-target-survey-element=sv-drag-drop-ghost-survey-element-name] {
  height: 100%;
  overflow: hidden;
  min-width: 4px;
}
.svc-question__adorner--start-with-new-line[data-sv-drop-target-survey-element=sv-drag-drop-ghost-survey-element-name] .svc-question__content.svc-question__content.svc-question__content {
  border: 0;
  padding: 0;
}

[data-sv-drop-target-survey-element=sv-drag-drop-ghost-survey-element-name] {
  border: none;
  height: 0;
}
[data-sv-drop-target-survey-element=sv-drag-drop-ghost-survey-element-name] .svc-question__drag-area {
  display: none;
}
[data-sv-drop-target-survey-element=sv-drag-drop-ghost-survey-element-name] .svc-question__drag-element {
  display: none;
}
[data-sv-drop-target-survey-element=sv-drag-drop-ghost-survey-element-name] .svc-question__content {
  background: transparent;
  box-shadow: none;
}

.svc-hovered > .svc-question__content > .svc-question__drag-area {
  visibility: visible;
}

.svc-question__content.svc-question__content--selected > .svc-question__drag-area {
  visibility: visible;
  z-index: 1;
}

.svc-question__drag-area {
  position: absolute;
  cursor: move;
  top: 0;
  left: 0;
  visibility: hidden;
  height: calc(3.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.svc-question__drag-element {
  width: 100%;
  height: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  opacity: 0.5;
}
.svc-question__drag-element use {
  fill: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
}

.sd-panel .svc-question__content--image:not(.svc-question__content--empty) {
  padding-left: 1px;
  padding-right: 1px;
}
.sd-panel .svc-question__content .sd-table-wrapper::before {
  left: calc(-1 * var(--sd-base-padding) + 1px);
}
.sd-panel .svc-question__content .sd-table-wrapper::after {
  right: calc(-1 * var(--sd-base-padding) + 1px);
}
.sd-panel .svc-question__content {
  border-radius: 0;
  border: 1px dashed var(--sjs-border-default, var(--border, #d6d6d6));
  box-shadow: none;
}
.sd-panel .svc-hovered .svc-question__content:not(.svc-question__content--dragged) {
  border: 1px solid var(--sjs-secondary-backcolor-light, var(--secondary-light, rgba(255, 152, 20, 0.25)));
  box-shadow: 0 0 0 1px var(--sjs-secondary-backcolor-light, var(--secondary-light, rgba(255, 152, 20, 0.25))) inset;
}
.sd-panel .svc-question__content.svc-question__content--selected:not(.svc-question__content--dragged) {
  border: 1px solid var(--sjs-secondary-backcolor, var(--secondary, #ff9814));
  box-shadow: 0 0 0 1px var(--sjs-secondary-backcolor, var(--secondary, #ff9814)) inset;
}

.sd-page__row.sd-row--multiple .svc-question__content--empty .sd-row__question,
.sd-page__row.sd-row--multiple .svc-question__content--empty .sd-row__panel {
  height: 0;
}

.sd-panel .svc-row--ghost.svc-row--ghost .svc-question__content.svc-question__content {
  border: none;
}

.svc-widget__content {
  width: 100%;
}
.svc-widget__content .sd-question__content {
  pointer-events: none;
}

.svc-question__content .sd-paneldynamic__footer,
.svc-question__content .sd-paneldynamic__separator {
  display: none;
}

.svc-panel__add-new-question-wrapper {
  container-type: inline-size;
  width: 100%;
  display: flex;
  justify-content: center;
}

.svc-panel__placeholder_frame-wrapper {
  container-type: inline-size;
  width: 100%;
  display: flex;
  flex-grow: 1;
  box-sizing: border-box;
}

.svc-question__content--empty-template .sd-question--paneldynamic,
.svc-question__content--empty-template .sd-question__content,
.svc-question__content--empty-template sv-ng-paneldynamic-question,
.svc-question__content--empty-template .sd-paneldynamic,
.svc-question__content--empty-template .sd-paneldynamic__panels-container,
.svc-question__content--empty-template .sd-paneldynamic__panel-wrapper,
.svc-question__content--empty-template .svc-question__adorner,
.svc-question__content--empty-template .svc-question__adorner > div {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.svc-question__content--empty-template .sd-question--paneldynamic .svc-panel__placeholder_frame,
.svc-question__content--empty-template .sd-question__content .svc-panel__placeholder_frame,
.svc-question__content--empty-template sv-ng-paneldynamic-question .svc-panel__placeholder_frame,
.svc-question__content--empty-template .sd-paneldynamic .svc-panel__placeholder_frame,
.svc-question__content--empty-template .sd-paneldynamic__panels-container .svc-panel__placeholder_frame,
.svc-question__content--empty-template .sd-paneldynamic__panel-wrapper .svc-panel__placeholder_frame,
.svc-question__content--empty-template .svc-question__adorner .svc-panel__placeholder_frame,
.svc-question__content--empty-template .svc-question__adorner > div .svc-panel__placeholder_frame {
  flex-grow: 1;
}

.svc-panel__placeholder_frame {
  border: 1px dashed var(--sjs-border-default, var(--border, #d6d6d6));
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: calc(11 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(8 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(10 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  flex-grow: 1;
}

.svc-panel__add-new-question-container {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.svc-panel__add-new-question {
  align-items: center;
  margin-top: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  margin-left: 0;
  margin-right: 0;
  width: auto;
  height: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  line-height: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  vertical-align: baseline;
  text-align: center;
  cursor: pointer;
  user-select: none;
  border: none;
  box-shadow: none;
  padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.svc-panel__add-new-question:hover {
  outline: none;
}
.svc-panel__add-new-question .svc-text {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  margin: var(--sjs-base-unit, var(--base-unit, 8px)) calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  line-height: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  color: var(--sjs-primary-backcolor, var(--primary, #19b394));
  text-wrap: nowrap;
}

.svc-panel__add-new-question-icon {
  margin: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  display: none;
}
.svc-panel__add-new-question-icon use {
  fill: var(--sjs-primary-backcolor, var(--primary, #19b394));
}

@container (max-width: 496px) {
  .svc-panel__placeholder_frame {
    padding: calc(11 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(10 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
}
@container (max-width: 176px) {
  .svc-panel__placeholder {
    display: none;
  }
  .svc-panel__placeholder_frame {
    padding: calc(10 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(10 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-panel__placeholder_frame .svc-panel__add-new-question {
    margin: 0;
  }
  .svc-panel__placeholder_frame .svc-panel__add-new-question-icon,
  .svc-panel__add-new-question-container .svc-panel__add-new-question-icon {
    display: block;
  }
  .svc-panel__placeholder_frame .svc-panel__question-type-selector,
  .svc-panel__add-new-question-container .svc-panel__question-type-selector {
    display: none;
  }
  .svc-panel__placeholder_frame .svc-panel__add-new-question,
  .svc-panel__add-new-question-container .svc-panel__add-new-question {
    padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-panel__placeholder_frame .svc-panel__add-new-question .svc-text,
  .svc-panel__add-new-question-container .svc-panel__add-new-question .svc-text {
    display: none;
  }
}
.svc-panel__question-type-selector-popup {
  height: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  width: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  position: absolute;
  right: 0;
  bottom: 0;
}

.svc-panel__question-type-selector {
  appearance: none;
  display: flex;
  height: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding: var(--sjs-base-unit, var(--base-unit, 8px));
  box-sizing: border-box;
  border: none;
  border-radius: 2px;
  background-color: transparent;
  cursor: pointer;
  margin-right: var(--sjs-base-unit, var(--base-unit, 8px));
  outline: none;
  top: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  margin: 0;
  position: absolute;
  right: 0;
}
.svc-panel__question-type-selector use {
  fill: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
}
.svc-panel__question-type-selector:hover {
  background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
}
.svc-panel__question-type-selector:focus {
  background-color: var(--sjs-primary-backcolor-light, var(--primary-light, rgba(25, 179, 148, 0.1)));
}

.sv-list__container .sv-action-bar-item--secondary .sv-list__item-icon use {
  fill: var(--sjs-secondary-backcolor, var(--secondary, #ff9814));
}

.sd-panel .svc-row {
  margin-top: var(--sjs-base-unit, var(--base-unit, 8px));
}
.sd-panel .svc-row:first-of-type {
  margin-top: 0;
}
.sd-panel .svc-row .sd-row {
  margin-top: 0;
  margin-bottom: 0;
  gap: var(--sjs-base-unit, var(--base-unit, 8px));
}

.svc-question__content .sd-element__header--location-top {
  margin-top: 0;
}
.svc-question__content .sd-element--complex > .sd-element__header--location-top {
  padding-bottom: calc(0.5 * var(--sd-base-vertical-padding));
  padding-top: 0;
}
.svc-question__content .sd-element--complex > .sd-element__header--location-top:after {
  display: none;
}
.svc-question__content .sd-panel__content {
  gap: 0;
  padding-top: 0;
}
.svc-question__content .sd-table__cell--detail-panel .sd-panel__content {
  padding-top: var(--sjs-base-unit, var(--base-unit, 8px));
}
.svc-question__content .sd-paneldynamic__panel-wrapper {
  padding: 0;
}
.svc-question__content .sd-table__cell--actions .sv-action-bar-item:disabled {
  background: var(--sjs-general-backcolor, var(--background, #fff));
  opacity: 1;
}
.svc-question__content .sd-table__cell--actions .sv-action-bar-item:disabled use {
  fill: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
}
.svc-question__content .sd-table__row-disabled > .sd-table__cell {
  opacity: 1;
}

.svc-question__content .svc-carry-forward-panel {
  margin-top: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.svc-question__content .sd-question__content {
  min-width: min(8 * (var(--sjs-base-unit, var(--base-unit, 8px))), 100%);
  margin-left: calc(-5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  margin-right: calc(-5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding-left: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding-right: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  overflow-x: auto;
}
.svc-question__content .sd-composite .sd-question__content {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

.svc-question__content--ranking .svc-carry-forward-panel {
  margin-top: 0;
}

.svc-required-action .sv-svg-icon use {
  fill: var(--sjs-secondary-backcolor, var(--secondary, #ff9814));
}

.svc-required-action--active:not(.sv-action-bar-item--pressed):enabled:hover,
.svc-required-action--active:not(.sv-action-bar-item--pressed):enabled:focus,
.svc-required-action--active {
  background-color: var(--sjs-secondary-backcolor-semi-light, rgba(255, 152, 20, 0.1));
}